.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* -------------------Common Css---------------- */
a {
  text-decoration: none !important;
}
.fs12{
  font-size: 12px;
}
.fs13{
  font-size: 13px;
}
.fs14{
  font-size: 14px;
}

.bg_dark {
  background-color: #000 !important;
  color: #fff !important;
  height: 100vh;
}

.bg_white {
  background-color: #fff !important;
  color: #000 !important;
  height: 100vh;
}

.lightbg {
  background-color: #1e2022 !important;
  height: 100vh;
}

.regPhoto {
  height: 200px;
  width: 100%;
  border: dashed;
  text-align: center;
  line-height: 200px;
  cursor: pointer;
}

.isPhoto {
  height: 200px;
  width: 100%;
  border: dashed;
  text-align: center;
  line-height: 200px;
  cursor: pointer;
}

.isPhotoX {
  position: absolute;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
  right: 0;
}

.boxShadow {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 1.41437px 0.70718px rgb(0 0 0 / 20%);
}

.rtin-calender {
  background-color: #fdc800;
  position: relative;
  padding-top: 20px;
  padding-right: 0px;
  text-align: center;
  min-width: 100px;
  height: fit-content;
}

.eventYear {
  background-color: #002147;
  color: #fff;
  padding: 5px 10px;
}

/* -----------------Home------------- */
.showHide {
  position: absolute;
  right: 22px;
  margin-top: -25px;
}


/* -----------------Left Menu------------- */
.leftItem {
  padding: 10px 0;
  border-bottom: 1px solid #000;
  text-align: center;
  cursor: pointer;
}

.leftActive {
  padding: 10px 0;
  border: 1px solid brown;
  text-align: center;
  cursor: pointer;
}


/* -----------------Right Menu------------- */
.mainHome {
  min-height: 100vh;
  display: flex;
  font-family: 'Roboto', sans-serif;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--bg);
  color: var(--color);
  perspective: 1200px;

}

.cloak__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.cloak__container {
  height: 250vmax;
  width: 250vmax;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cloak {
  animation: swing var(--speed) infinite alternate-reverse ease-in-out;
  height: 100%;
  width: 100%;
  transform-origin: 50% 30%;
  transform: rotate(calc(var(--swing-x) * -0.25deg));
  background: radial-gradient(40% 40% at 50% 42%, transparent, #000 35%);
}

.info {
  text-align: center;
  line-height: 1.5;
  max-width: clamp(16rem, 90vmin, 25rem);
}

.headerMN12 {
  margin: 0 -12px !important;
  /* position: fixed !important;
  width: inherit;
  z-index: 1; */
}

.headerSignout {
  position: initial !important;
  right: 20px !important;
}

.headerDropdown .dropdown-menu {
  right: inherit !important;
  width: auto !important;
  left: auto !important;
}